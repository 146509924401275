import {handleActions} from 'redux-actions'
import {LOG_OUT, SET_AUTH, SET_USER} from '../actions/actionTypes'

const initialState = {
  isAuth: false,
  user: null,
}

export const baseReducer = handleActions(
  {
    SET_AUTH: (state, {payload}) => {
      return {
        ...state,
        isAuth: payload,
      }
    },
    LOG_OUT: () => initialState,
    SET_USER: (state, {payload}) => ({
      ...state,
      user: payload,
    }),
  },
  initialState,
)
